<template>
<div class="container">
	<h1 class="title">Términos de tratamiento de datos personales</h1>

    <p class="paragraph">
        <strong>{{enterprise}}</strong> <br>
        !Tu mejor amigo en manos amigas¡
    </p>
    <p class="paragraph">
        Última actualización: {{lastUpdate}}
    </p>
    <p class="paragraph">
        LEE CUIDADOSAMENTE ESTAS POLÍTICAS DE TRATAMIENTO DE DATOS, YA QUE CONTIENEN  INFORMACIÓN 
        IMPORTANTE SOBRE EL MANEJO DE TU INFORMACIÓN, ASÍ COMO LAS  OBLIGACIONES RELACIONADAS CON 
        EL USO DE LA PLATAFORMA <strong>{{enterprise}}</strong>. 
    </p>
    <p class="paragraph">
        Bienvenidos a <strong>{{enterprise}}</strong> una plataforma web (denominada en adelante 
        <strong>{{enterprise}}</strong>”, 
        “EMPRESA” , “PORTAL” , “PLATAFORMA” o “SITIO”) propiedad de JOSE LUIS CERON con 
        domicilio en Colombia e identificada con C.C. 1061726121. <strong>{{enterprise}}</strong> ofrece los 
        servicios  descritos en los TÉRMINOS Y CONDICIONES los cuales podrás conocer y 
        verificar en la  PLATAFORMA.</p>

    <p class="paragraph">
        <strong>{{enterprise}}</strong> está comprometida con el respeto y la protección de los datos personales de 
        los  Usuarios, por eso, para garantizar el adecuado cumplimiento de la 
        Ley 1581 de 2012, el  Decreto 1377 de 2013 y el Reglamento General de Protección de 
        Datos Personales (en  adelante, RGPD).</p>

    <p class="paragraph">
        Usted y todas las personas que utilicen <strong>{{enterprise}}</strong>, habiéndose o no registrado, se 
        denominará en  este documento como USUARIO(S) o TITULAR. El Usuario que usa y 
        disfruta de GO GUSU debe  conocer y aceptar las siguientes Políticas de Privacidad 
        (“Políticas de Privacidad”). 
    </p>


    <div>
      <p class="subtitle">I. PRINCIPIOS</p>

        <p class="paragraph"><strong>{{enterprise}}</strong>, está comprometida con la implementación de mecanismos 
        relacionados con la  información personal de terceros para garantizar, de manera armónica 
        e integral, los  siguientes principios: </p>
          
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Principio de legalidad en materia de Tratamiento de 
              datos: El Tratamiento a que se  refiere la presente ley es una actividad reglada que debe sujetarse a 
              lo establecido en  ella y en las demás disposiciones que la desarrollen.</span>            
          </p> 
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Principio de finalidad: El Tratamiento debe obedecer a una finalidad definida,  legítima, explícita e informada al Titular, de acuerdo con la Constitución y la Ley y el  Reglamento General de Protección de Datos Personales.</span>            
          </p> 
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Principio de libertad: El Tratamiento sólo puede ejercerse con el consentimiento,  previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos  o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que  releve el consentimiento.</span>            
          </p> 
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Principio de veracidad o calidad: La información sujeta a Tratamiento debe ser veraz,  completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el  Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.</span>            
          </p> 
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">En el Tratamiento debe garantizarse el derecho del Titular  a obtener del Responsable del Tratamiento o del Encargado del Tratamiento, en  cualquier momento y sin restricciones, información acerca de la existencia de datos  que le conciernan.</span>            
          </p> 
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Principio de acceso y circulación restringida: El Tratamiento se sujeta a los límites que  se derivan de la naturaleza de los datos personales, de las disposiciones de la presente  ley y la Constitución. En este sentido, el Tratamiento sólo podrá hacerse por personas 
              autorizadas por el Titular y/o por las personas previstas en la ley; Los datos  personales, salvo la información pública, no podrán estar disponibles en Internet u  otros medios de divulgación o comunicación masiva, salvo que el acceso sea  técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares  o terceros autorizados conforme a la presente ley.</span>            
          </p> 
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Principio de seguridad: La información sujeta a Tratamiento por el Responsable del  Tratamiento o Encargado del Tratamiento a que se refiere la presente ley, se deberá  manejar con las medidas técnicas, humanas y administrativas que sean necesarias  para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso  o acceso no autorizado o fraudulento.</span>            
          </p> 
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Principio de confidencialidad: Todas las personas que intervengan en el Tratamiento  de datos personales que no tengan la naturaleza de públicos están obligadas a  garantizar la reserva de la información, inclusive después de finalizada su relación con  alguna de las labores que comprende el Tratamiento, pudiendo sólo realizar  suministro o comunicación de datos personales cuando ello corresponda al desarrollo  de las actividades autorizadas en la presente ley y en los términos de la misma.</span>            
          </p> 
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">El principio de limitación del plazo de conservación: Sólo pueden tratarse los datos  adecuados, pertinentes y necesarios para una finalidad, la conservación de esos datos  debe limitarse en el tiempo al logro de los fines que el tratamiento persigue. Una vez  que esas finalidades se han alcanzado, los datos deben ser borrados o, al menos,  desprovistos de todo elemento que permita identificar a los interesados.</span>            
          </p> 
    </div>

    <div>
      <p class="subtitle">II. ALCANCE DE LA POLÍTICA</p>
        <p class="paragraph">Las presentes políticas aplican para todos los USUARIOS de <strong>{{enterprise}}</strong> a nivel nacional e  internacional.  </p>
    </div>

    <div>
      <p class="subtitle">III. DEFINICIONES</p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Titular: Cada una de las personas naturales con las que se relacionan los datos  personales.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Usuario: Es la persona que hace uso de los servicios de la PLATAFORMA.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Compradores: Es la persona que vive la experiencia en la plataforma, compra los  términos de referencia y opcionalmente, puede autorizar que sus datos sean  compartidos con los Proveedores.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Proveedores: Son las personas que cotizan y venden sus productos y/o servicios a  los compradores.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Datos Personales: Conjunto de información susceptible de relacionarse a una o más  personas naturales.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Datos Sensibles: Se entiende por datos sensibles aquellos que afectan la intimidad del  Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que  revelen el origen racial o étnico, la orientación política, las convicciones religiosas o  filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos  o que promueva intereses de cualquier partido político o que garanticen los derechos  y garantías de partidos políticos de oposición, así como los datos relativos a la salud,  a la vida sexual, y los datos biométricos.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Tratamiento de Datos (o “Tratamiento”): Cualquier operación o conjunto de  operaciones sobre datos personales, tales como la recolección, almacenamiento, uso,  circulación o supresión.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Transferencia de Datos (o “Transferencia): Es cuando el Responsable y/o Encargado  del Tratamiento de datos personales, ubicado en Colombia, envía la información o los  datos personales a un receptor, que a su vez es Responsable del Tratamiento y se  encuentra dentro o fuera del país.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Responsable del Tratamiento: Persona natural o jurídica, que por sí misma o en asocio  con otros, decida sobre la base de datos y/o el Tratamiento de los datos.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí  misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta  del Responsable del Tratamiento.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Base de Datos: Conjunto organizado de datos personales que sea objeto de  Tratamiento.</span>
        </p>
        <p class="paragraph padded">
          <span class="bullet">●</span><span class="margin">Reglamento General de Protección de Datos: (En adelante, RGPD/GDPR).Es la nueva  normativa que regula la protección de los datos de los ciudadanos que vivan en la  Unión Europea.</span>
        </p>
    </div>

    <div>
      <p class="subtitle">IV. REGLAMENTO GENERAL DE PROTECCIÓN DE DATOS</p>
        <p class="paragraph"><strong>{{enterprise}}</strong> implementa su recolección y tratamiento de 
        datos, de conformidad con la  normatividad legal vigente, por lo cual <strong>{{enterprise}}</strong> adecua y posee 
        lo siguiente:
        </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Utiliza para el manejo de la información, herramientas e integraciones con terceros  que cumplen con la Ley de protección de datos y con el reglamento RGPD/GDPR con  fines específicos sin derecho a ser compartidos o usados con un propósito distinto al  que autorizó inicialmente el usuario.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Cumple con los principios generales del RGPD/GDPR en cuanto a calidad de los datos,  consentimiento y derecho de información; y les ofrece a todos los titulares de los  datos, los derechos de acceso, rectificación, portabilidad de los datos, restricción,  supresión y oposición.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Cumple con los criterios generales de licitud, lealtad y transparencia del tratamiento  de datos. Los fines están determinados, explícitos y son legítimos.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Aplica la minimización de datos, al recolectar sólo los datos adecuados, pertinentes y  limitados a lo necesario. Garantiza la exactitud y actualización de estos y los conserva  sólo por el tiempo necesario para los fines del tratamiento. </span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Adecua las medidas técnicas y organizativas apropiadas para garantizar un nivel de  seguridad adecuado.</span>
          </p>
    </div>

    <div>
      <p class="subtitle">V. AUTORIZACIÓN DEL TITULAR PARA EL TRATAMIENTO DE DATOS</p>
        <p class="paragraph"><strong>{{enterprise}}</strong>, requiere del consentimiento libre, previo, expreso e informado del Titular  de los datos personales para el tratamiento de los mismos, excepto en los casos  expresamente autorizados en la ley. </p>
        <p class="paragraph">Dicha autorización será otorgada por: el Titular, quien deberá acreditar su identidad en forma  suficiente, según la forma indicada por la PLATAFORMA o los causahabientes, acudientes,  representantes o apoderados del titular, quienes deberán acreditar tal calidad,  representación o apoderamiento. </p>
        <p class="paragraph">El Reglamento General de Protección de Datos determina la necesidad de establecer  garantías de seguridad adecuadas contra el tratamiento no autorizado o ilícito, contra la  pérdida de los datos personales, la destrucción o el daño accidental. Esto implica el  establecimiento de medidas técnicas y organizativas, como la presente política, encaminadas  a asegurar la integridad y confidencialidad de los datos personales y la posibilidad de  demostrar, tal y como establece el artículo 5 numeral 2 del Reglamento, que estas medidas  se han llevado a la práctica (responsabilidad proactiva).</p>
        <p class="paragraph"><strong>{{enterprise}}</strong> obtendrá la autorización mediante diferentes medios, entre ellos el Correo  electrónico, mediante el registro en la PLATAFORMA, en el formulario que despliega la  PLATAFORMA para solicitar un servicio o en cualquier otro formato que permita evidenciar  que se divulgó la Política de Tratamiento de Información y que el titular autoriza el  Tratamiento de sus datos personales. </p>
        <p class="paragraph"><strong>{{enterprise}}</strong> podrá entregar la información de los Titulares de los Datos a los siguientes:</p>

          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">A los Titulares de los datos, sus herederos o representantes en cualquier momento y  a través de cualquier medio cuando así lo soliciten a <strong>{{enterprise}}</strong>.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">A las entidades judiciales o administrativas en ejercicio de funciones que eleven algún  requerimiento a <strong>{{enterprise}}</strong> para que le sea entregada la información. </span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">A los terceros que sean autorizados por alguna ley de la república de Colombia o el  Reglamento de General de Protección de Datos.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin"> A los terceros a los que el Titular de los Datos autorice expresamente para entregar la  información y cuya autorización sea entregada a la <strong>{{enterprise}}</strong>.</span>
          </p>

        <p class="paragraph">La autorización del Titular no será necesaria en los siguientes casos:</p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Información requerida por una entidad pública o administrativa en ejercicio de sus  funciones legales o por orden judicial.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Datos de naturaleza pública. </span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Casos de urgencia médica o sanitaria. </span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Tratamiento de información autorizado por la ley para fines históricos, estadísticos o  científicos.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">El tratamiento es necesario por razones de interés público en el ámbito de la salud  pública, como la protección frente a amenazas transfronterizas graves para la salud.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">El tratamiento es necesario por razones de un interés público esencial, sobre la base  del Derecho de la Unión o de los Estados miembros, el cual en todo caso debe ser  proporcional al objetivo perseguido.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Datos relacionados con el Registro Civil de las Personas.</span>
          </p>
        <p class="paragraph">Los titulares de los datos personales pueden en cualquier momento revocar la autorización  otorgada a la PLATAFORMA para el Tratamiento de sus datos personales o solicitar la  supresión de los mismos, siempre y cuando no lo impida una disposición legal o contractual.  La retirada de la autorización no afectará a la licitud del tratamiento basada en el  consentimiento previo a su retirada.</p>
    </div>

    <div>
      <p class="subtitle">VI. ¿CUÁLES SON LOS DATOS QUE RECOGE <strong>{{enterprise}}</strong> DE MI?</p>
        <p class="paragraph"><strong>{{enterprise}}</strong> recolecta de los Titulares la siguiente información: </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Nombre y apellido completo. </span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Correo electrónico. </span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Dirección.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Número de contacto, teléfono fijo o celular. </span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Ciudad.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">País.</span>
          </p>
    </div>

    <div>
      <p class="subtitle">VII. ¿CON QUÉ FINALIDADES RECOGEN MIS DATOS? </p>
        <p class="paragraph"><strong>{{enterprise}}</strong>, en el desarrollo de su objeto y sus relaciones con sus usuarios, recolecta datos que  se limitan a aquellos datos personales que son pertinentes y adecuados para las siguientes  finalidades:</p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para que el USUARIO pueda comprar los servicios ofrecidos en la plataforma.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para TRANSFERIR, en caso de que el USUARIO COMPRADOR así lo autorice, sus datos  a los Proveedores que le puedan cotizar, vender y promocionar los  productos que necesita.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para la creación y verificación de la cuenta en PLATAFORMA por parte del Titular.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Verificar la identidad del Titular al momento de acceder a la cuenta.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Comunicarse con la PLATAFORMA respecto al soporte de la cuenta o cualquiera de  los Servicios que el Titular utiliza.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Comparar la información con fines de estadística, precisión y verificación.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para enviar notificación y mensajes a través de correo electrónico o mensajes de texto.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para brindar una experiencia personalizada e implementar tus preferencias. </span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para entender mejor a los usuarios y cómo usan e interactúan con <strong>{{enterprise}}</strong> y sus Servicios.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para crear anuncios relacionados con el usuario. </span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para brindar Servicios personalizados, ofertas y promociones en la PLATAFORMA y en  sitios web de terceros.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para brindar opciones y ofertas específicas de acuerdo con la ubicación y preferencias  de los usuarios.  </span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para cumplir con nuestras políticas y obligaciones, incluyendo, entre otras, las  divulgaciones y respuestas a cualquier solicitud de las autoridades policiales o 
                entidades regulatorias de acuerdo con cualquier ley, norma, reglamento, orden  judicial 
                o gubernamental aplicable, autoridad reguladora de Jurisdicción competente,  solicitud de 
                descubrimiento o proceso legal similar.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para cobrar las tarifas por los servicios prestados.</span>
          </p>
          <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Para resolver los problemas técnicos reportados o que se presenten en la  PLATAFORMA.</span>
          </p>
        <p class="paragraph">En relación con las anteriores finalidades, <strong>{{enterprise}}</strong> podrá:  </p>

          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Realizar la TRANSFERENCIA de los datos personales del Usuario comprador (titular) a  proveedores, que le puedan cotizar, vender y promocionar los  productos que necesita.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Realizar publicaciones de trabajos o estudios científicos, en estos casos los datos serán  anonimizados de manera tal que no se pueda identificar el titular del dato.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Obtener, almacenar, compilar, intercambiar, actualizar, recolectar, procesar,  reproducir y/o disponer de los datos o información parcial o total de los Titulares de  los datos personales.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Clasificar, ordenar y segmentar la información suministrada por el Titular de los datos.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Extender la información que obtenga, en los términos de la Ley, a las empresas con  las que contrata los servicios de recolección, almacenamiento, manejo y Tratamiento  de sus Bases de Datos, previas las debidas autorizaciones y requerimientos legales que  en ese sentido sean necesarias.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Transferir los datos o información parcial o total a sus filiales, comercios, empresas  y/o entidades afiliadas.</span></p>
    </div>

    <div>
      <p class="subtitle">VIII. ¿QUÉ DERECHOS TENGO CÓMO TITULAR DE MIS DATOS?</p>
        <p class="paragraph">El TITULAR de los datos personales que recoge <strong>{{enterprise}}</strong> cuenta con los siguientes derechos: </p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Derecho de acceso: Se facilitará a al titular copia de los datos personales de los que se  disponga junto con la finalidad para la que han sido recogidos, la identidad de los  destinatarios de los datos, los plazos de conservación previstos o el criterio utilizado  para determinarlo, la existencia del derecho a solicitar la rectificación o supresión de  sus datos personales, así como la limitación o la oposición a su tratamiento. El titular  de los datos puede ejercer este derecho de acceso en los canales de atención que  dispone la PLATAFORMA.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Derecho a conocer, actualizar, rectificar y consultar sus Datos Personales: El titular  puede solicitar conocer, actualizar, rectificar y consultar los Datos Personales suyos  que almacena la PLATAFORMA en cualquier momento. El Titular deberá indicar en la  solicitud a qué datos se refiere y la corrección que haya de realizarse, aportando,  cuando sea preciso, la documentación justificativa de la inexactitud o carácter  incompleto de los datos objeto de tratamiento. Cualquier pérdida o daño causado a  la Plataforma o al responsable de la Plataforma o a cualquier tercero por motivo de  una comunicación de información errónea, inexacta o incompleta en los formularios  de registro o en su cuenta, será responsabilidad exclusiva del USUARIO.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Derecho de Supresión: En el derecho de supresión se eliminarán los datos de los  titulares cuando estos manifiesten su negativa al tratamiento y no exista una base legal que lo impida, no sean necesarios en relación con los fines para los que fueron  recogidos, retiren el consentimiento prestado y no haya otra base legal que legitime  el tratamiento o éste sea ilícito.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Derecho de portabilidad: En el derecho de portabilidad, los interesados pueden  solicitar recibir copia de sus datos personales en un formato estructurado, de uso  común y lectura mecánica. Asimismo, tienen derecho a solicitar que sean transmitidos  directamente a un nuevo responsable, cuya identidad deberá ser comunicada, cuando  sea técnicamente posible.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Solicitar en cualquier momento una prueba de la autorización otorgada a la  PLATAFORMA.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Presentar ante la Superintendencia de Industria y Comercio o la autoridad en materia  de protección de datos que sea pertinente, las quejas que considere para hacer valer  su derecho al Habeas Data frente a <strong>{{enterprise}}</strong>. </span></p>
    </div>

    <div>
      <p class="subtitle">IX. ¿CUÁLES SON LOS DEBERES DE <strong>{{enterprise}}</strong> COMO RESPONSABLE DEL TRATAMIENTO?</p>
        <p class="paragraph"><strong>{{enterprise}}</strong>, reconoce que los Datos Personales son propiedad de sus Titulares y que únicamente  ellos podrán decidir sobre estos. Teniendo en cuenta la naturaleza, el ámbito, el contexto y  los fines del tratamiento, así como los riesgos para los derechos y libertades de las personas  físicas, el responsable del tratamiento aplicará medidas técnicas y organizativas apropiadas a  fin de brindar que el tratamiento es conforme con las normas o disposiciones en materia de  protección de datos.</p>
        <p class="paragraph">De acuerdo a lo anterior, asume los siguientes deberes en su calidad de Responsable del  Tratamiento:</p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Contar con el canal en la aplicación para obtener la autorización expresa por parte del  Titular para realizar cualquier tipo de tratamiento de datos.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Solicitar y conservar, en las condiciones previstas en la Ley de Protección de Datos  Personales, copia de la respectiva autorización otorgada por el Titular.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Informar de manera clara y expresa a Titulares de los Datos el Tratamiento al cual  serán sometidos los mismos y la Finalidad de dicho Tratamiento por virtud de la  autorización otorgada.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Garantizar al Titular de la información, en todo tiempo, el pleno y efectivo ejercicio  del derecho de hábeas data.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo  tratamiento este previamente autorizado de conformidad con lo previsto en la Ley de  Datos Personales.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Informar los derechos que le asisten a todos los Titulares respecto a sus datos.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Mantener y velar por la seguridad de los registros de Datos Personales almacenados  para impedir su deterioro, pérdida, alteración, uso no autorizado o fraudulento.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Realizar periódica y oportunamente la actualización y rectificación de los datos, cada  vez que los Titulares de los mismos le reporten novedades o solicitudes.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Informar la identificación, dirección física y/o electrónica y teléfono de la persona o  área que tendrá la calidad de Responsable del Tratamiento.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">En caso de haber cambios sustanciales en el contenido de esta Política de Tratamiento  de Información los cuales puedan afectar el contenido de la autorización que el Titular le ha otorgado a <strong>{{enterprise}}</strong>, esta le comunicará oportunamente y de manera eficiente los  cambios antes de o a más tardar al momento de implementación de las nuevas  políticas. </span></p>
    </div>

    <div>
      <p class="subtitle">X. TRANSFERENCIA DE DATOS PERSONALES CON TERCEROS.</p>
        <p class="paragraph"><strong>{{enterprise}}</strong> podrá compartir con terceros la información otorgada por el Titular al momento de  registrarse en la PLATAFORMA, siempre y cuando el Titular lo autorice y únicamente para  las finalidades descritas en esta Política.</p>
        <p class="paragraph">Para el manejo externo de los datos, es decir, cuando se transfiere los datos personales a  terceros, como a los proveedores o empresas afiliadas, entre otros, nos aseguraremos de  que ellos cumplan con la legislación en materia de protección de datos, con las medidas de  seguridad y las mismas garantías otorgadas por nosotros, de acuerdo con el artículo 28 del  RGPD y la Ley 1581 de 2012.</p>
        <p class="paragraph">La Ley 1581 de 2012 prohíbe la transferencia de datos personales de cualquier tipo a países  que no proporcionen niveles adecuados de protección de datos.</p> 
        <p class="paragraph">Esta prohibición NO REGIRÁ cuando se trate de:</p> 
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Información respecto de la cual el Titular haya otorgado su autorización expresa e  inequívoca para la transferencia.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Intercambio de datos de carácter médico, cuando así lo exija el tratamiento del Titular  por razones de salud o higiene pública.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Transferencias bancarias o bursátiles, conforme a la legislación que les resulte  aplicable.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Transferencias acordadas en el marco de tratados internacionales en los cuales la  República de Colombia sea parte, con fundamento en el principio de reciprocidad.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Transferencias necesarias para la ejecución de un contrato entre el Titular y el Responsable del Tratamiento, o para la ejecución de medidas precontractuales  siempre y cuando se cuente con la autorización del Titular.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Transferencias legalmente exigidas para la salvaguardia del interés público, o para el  reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.</span></p>
        <p class="paragraph">Asimismo, te recomendamos consultar la política de protección de datos del proveedor  correspondiente. Para el manejo interno de los datos, éstos podrán ser conocidos por el  personal autorizado de <strong>{{enterprise}}</strong>, los cuales deberán conocer los procedimientos de seguridad y  recolección de datos.</p>
    </div>

    <div>
      <p class="subtitle">XI. ¿QUÉ HACE <strong>{{enterprise}}</strong> POR LA SEGURIDAD DE LOS DATOS?</p>
        <p class="paragraph"><strong>{{enterprise}}</strong> prevé, cuida y adopta las medidas técnicas, humanas y administrativas que sean  necesarias para mantener la seguridad de tu información y se procura por evitar su pérdida,  adulteración, acceso o consulta de terceros no autorizados a la plataforma través de  tecnologías estándar de la industria y los procedimientos internos, incluso mediante el uso de  símbolos y mecanismos de encriptación. Sin embargo, <strong>{{enterprise}}</strong> no garantiza que nunca se  produzcan accesos no autorizados. </p>
        <p class="paragraph"><strong>{{enterprise}}</strong> cuenta con protocolos de seguridad y acceso a los sistemas de información,  almacenamiento y procesamiento. Permanentemente se realiza monitoreo al sistema a  través de análisis de vulnerabilidades. El personal de <strong>{{enterprise}}</strong> que realiza el tratamiento de los  datos personales ejecuta dichos protocolos con el fin de garantizar la seguridad de la  información.</p>
        <p class="paragraph"><strong>{{enterprise}}</strong> cumple con lo siguiente: </p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin"><strong>{{enterprise}}</strong> tiene el deber de notificar si existe una brecha de seguridad en la información a  los Usuarios dentro de las 72 horas a la misma.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">El acceso a las diferentes bases de datos se encuentra restringido incluso para los  empleados y colaboradores.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Todos los empleados y terceros han suscrito cláusulas de confidencialidad en sus  contratos y están comprometidos con la manipulación adecuada de las bases de datos  atendiendo a los lineamientos sobre tratamiento de la información establecida en la  Ley.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Es responsabilidad del usuario tener todos los controles de seguridad en sus equipos  o redes privadas para su navegación hacia nuestros portales. </span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Todo Tercero Titular de Datos Personales tiene derecho a realizar solicitudes  directamente a <strong>{{enterprise}}</strong> para suprimir su información personal y revocar la autorización  concedida al Usuario que utiliza la Plataforma.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Los dispositivos y ordenadores utilizados para el almacenamiento y el tratamiento de  los datos personales deberán mantenerse actualizados en la media posible.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">En los ordenadores y dispositivos donde se realice el tratamiento automatizado de los  datos personales se dispondrá de un sistema de antivirus que garantice en la medida  posible el robo y destrucción de la información y datos personales. El sistema de  antivirus deberá ser actualizado de forma periódica.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Para evitar accesos remotos indebidos a los datos personales se velará por garantizar  la existencia de un firewall activado y correctamente configurado en aquellos  ordenadores y dispositivos en los que se realice el almacenamiento y/o tratamiento  de datos personales.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Periódicamente se realizará una copia de seguridad en un segundo soporte distinto  del que se utiliza para el trabajo diario. La copia se almacenará en lugar seguro,  distinto de aquél en que esté ubicado el ordenador con los ficheros originales, con el  fin de permitir la recuperación de los datos personales en caso de pérdida de la  información.</span></p>
    </div>

    <div>
      <p class="subtitle">XII. ¿CÓMO PUEDO HACER UNA SOLICITUD, QUEJA O RECLAMO A <strong>{{enterprise}}</strong>?</p>
        <p class="paragraph">Todo titular o causahabiente de Datos Personales tiene derecho de forma gratuita a realizar  consultas y solicitudes a <strong>{{enterprise}}</strong> para conocer, actualizar, rectificar, suprimir información y  revocar la autorización o para realizar peticiones, quejas y reclamos respecto al Tratamiento  que <strong>{{enterprise}}</strong>, da a la información.</p>
        <p class="paragraph">Los Titulares pueden contactarse con el área Administrativa responsable de la atención de  peticiones, quejas y reclamos a través del correo electrónico: {{email}} </p>
        <p class="paragraph">La consulta debe contar con el nombre completo del Titular, la descripción de la consulta,  solicitud, petición, queja o reclamo, el teléfono de contacto y el correo electrónico. El titular  de la información deberá presentar y/o adjuntar los siguientes documentos:</p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Si se trata del Titular: Documento de identidad válido.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Si se trata del causahabiente: Documento de identidad válido, Registro Civil de  Defunción del Titular, Documento que acredite la calidad en que actúa y el número  del documento de identidad del Titular.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Si se trata de un representante legal y/o apoderado: Documento de identidad válido,  Documento que acredite la calidad de Representante legal y/o apoderado del titular  y el número del documento de identidad del Titular.</span></p>
        <p class="paragraph">En caso de que la consulta resulte incompleta, <strong>{{enterprise}}</strong> solicitará al interesado para que subsane  las fallas dentro de los cinco (5) días siguientes a la recepción del reclamo. Transcurrido un  mes (1) mes desde la fecha del requerimiento, sin que el solicitante presente la información  requerida, se entenderá que ha desistido de la reclamación o petición.</p>
        <p class="paragraph">La consulta será atendida en un término máximo de diez (10) días hábiles contados a partir  de la fecha de radicación. Cuando no fuere posible atender la consulta dentro de dicho  término, se informarán los motivos de la demora, y la fecha en que se atenderá su consulta,  la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del  primer término.</p>        
    </div>

    <div>
      <p class="subtitle">XIII. ¿PUEDO SOLICITAR LA REVOCATORIA DE LA AUTORIZACIÓN Y/O SUPRESIÓN DEL  DATO?</p>
        <p class="paragraph">Todo titular o causahabiente de Datos Personales tiene derecho a solicitar a <strong>{{enterprise}}</strong>, la  eliminación total o parcial de sus Datos Personales. Para ello se seguirá el siguiente  procedimiento:</p>
        <p class="paragraph">Los Titulares pueden contactarse con el área Administrativa responsable de la atención de  peticiones, quejas y reclamos a través del correo electrónico: {{email}}.</p>
        <p class="paragraph">La solicitud debe contar con el nombre completo del Titular, el teléfono de contacto y el  correo electrónico. El titular de la información deberá presentar y/o adjuntar los siguientes  documentos:</p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Si se trata del Titular: Documento de identidad válido.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Si se trata del causahabiente: Documento de identidad válido, Registro Civil de  Defunción del Titular, Documento que acredite la calidad en que actúa y el número  del documento de identidad del Titular.</span></p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Si se trata de un representante legal y/o apoderado: Documento de identidad válido,  Documento que acredite la calidad de Representante legal y/o apoderado del titular  y el número del documento de identidad del Titular.</span></p>
        <p class="paragraph">En caso de que la consulta resulte incompleta, <strong>{{enterprise}}</strong> solicitará al interesado para que subsane  las fallas dentro de los cinco (5) días siguientes a la recepción del reclamo. Transcurrido un  mes (1) desde la fecha del requerimiento, sin que el solicitante presente la información  requerida, se entenderá que ha desistido de la petición.</p>
        <p class="paragraph">La consulta será atendida en un término máximo de diez (10) días hábiles contados a partir  de la fecha de radicación. Cuando no fuere posible atender la consulta dentro de dicho  término, se informarán los motivos de la demora, y la fecha en que se atenderá su consulta,  la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del  primer término. </p>
        <p class="paragraph">La supresión de Datos operará y será definitiva siempre y cuando los mismos: (a) no estén  siendo tratados conforme a lo establecido por la legislación vigente, o (b) hayan dejado de  ser necesarios para la finalidad con la cual se recaudaron.</p>
        <p class="paragraph"><strong>{{enterprise}}</strong> podrá negar la eliminación cuando: (a) El Titular tenga el deber legal y/o contractual de  permanecer en la base de datos; (b) La supresión de los datos obstaculice actuaciones  judiciales o administrativas que se encuentren en curso. </p>
    </div>

    <div>
      <p class="subtitle">XIV. CAMBIOS A LA POLÍTICA DE PRIVACIDAD.</p>
        <p class="paragraph">Cualquier información del Usuario se encuentra regulada por Políticas de Privacidad de la  aplicación, por esto <strong>{{enterprise}}</strong> se reserva el derecho de modificarlas en cualquier momento, por  lo cual se recomienda al Usuario visitar la sección de perfil-políticas de privacidad con  frecuencia. En caso de cualquier cambio SUSTANCIAL, <strong>{{enterprise}}</strong> hará lo posible para publicar un  aviso de dicha modificación.</p>
        <p class="paragraph">Cualquier cambio en la Política de Privacidad empezará a regir a partir de la "última  actualización" y el uso continuado del servicio por el Usuario en la fecha de última revisión  constituirá aceptación de los mismos.</p>        
    </div>

    <div>
      <p class="subtitle">XV. VIGENCIA.</p>
        <p class="paragraph">Las presentes Políticas de Tratamiento de la Información entran en vigor a partir del 13 de  septiembre 2021 y las Bases de Datos que contienen la información de los Titulares tendrán una  vigencia de 10 años prorrogables por períodos iguales.</p>
    </div>

    <div>
      <p class="subtitle">XVI. ¿QUÉ PASA SI TENEMOS DIFERENCIAS?</p>
        <p class="paragraph">En caso de cualquier controversia entre el TITULAR y <strong>{{enterprise}}</strong> con relación a las Políticas de  Privacidad, las partes se comprometen a acudir a un método alternativo de solución de  conflictos (MASC) en caso tal, de que el conflicto no pueda ser resuelto por alguno de estos  métodos, acudirán ante la justicia ordinaria.</p>
    </div>

    <div>
      <p class="subtitle">XVII.  ¿Y SI TENGO DUDAS O INQUIETUDES? </p>
        <p class="paragraph">Si tienes alguna pregunta (o comentario) acerca de la Política de Privacidad, el Usuario puede  comunicarse al siguiente correo: {{email}}</p>
    </div>

    <div>
      <p class="subtitle">XVIII. DATOS DE CONTACTO. </p>
        <p class="paragraph"><strong>{{enterprise}}</strong> se encuentra ubicado en la siguiente dirección y los datos de contacto son los siguientes,  lo anterior para efectos de ser notificado de cualquier acto judicial o de cualquier tipo.</p>
          <p class="paragraph padded"><span class="bullet">●</span><span class="margin">Correo: {{email}}</span></p>
        <p class="paragraph">Última Actualización: {{lastUpdate}}. </p>
        <p class="paragraph"><strong>{{enterprise}}</strong> responderá en el menor plazo posible y en todo caso dentro de los plazos legales  establecidos.</p>
        <p class="paragraph">Gracias por leer nuestras POLÍTICAS DE TRATAMIENTO DE DATOS y por utilizar nuestros  servicios.</p>
    </div>
</div>
</template>

<script>

export default {
    name: 'terms_and_conditions',
    data(){
      return{                        
        enterprise: 'GO GUAU',
        email: 'contacto@goguau.co',
        line: '319 411 5792',
        lastUpdate: '13 de septiembre de 2021'
      }
    },
    watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
}
</script>

<style scoped>

	.title{
		padding: 2rem 0;
	}

	.subtitle{
		font: bold 20px 'Arial';
		color: #1155cc;
		line-height: 16px;
	}
	.subtitle2{
		font: bold 18px 'Arial';
		color: #1155cc;
		line-height: 14px;
	}

	.padded{
		padding-left: 68px;
	}

	.paragraph{
		text-align: justify;
		font: 20px;
		color: #666666;
		line-height: 19px;
	}

	.bullet{
		font: 13px 'Arial';
		color: #666666;
		line-height: 16px;
	}

	.number{
		font: 20px;
		color: #666666;
		line-height: 16px;
	}

	.margin{
		margin-left: 16px;
	}

</style>
